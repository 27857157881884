import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const Account = () => {
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';

  const [userData, setUserData] = useState({
    username: '',
    email: '',
    currentPassword: ''
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found. User not authenticated.');
          return;
        }

        const response = await axios.get(`${BackendAddress}/api/auth/getInfo`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUserData(prevState => ({
          ...prevState,
          username: response.data.username,
          email: response.data.email
        }));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [BackendAddress]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setError('');
    setSuccessMessage('');
  };

  const handlePasswordEditToggle = () => {
    setIsEditingPassword(!isEditingPassword);
    setError('');
    setSuccessMessage('');
  };

  const handleSaveChanges = async () => {
    if (!userData.currentPassword) {
      setError('Bitte das aktuelle Passwort eingeben, um Änderungen zu bestätigen.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. User not authenticated.');
        return;
      }

      const response = await axios.put(`${BackendAddress}/api/auth/changeEmailOrUsername `, {
        email: userData.email,
        username: userData.username,
        currentPassword: userData.currentPassword
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSuccessMessage(response.data.message);
      setIsEditing(false);
      setUserData(prevState => ({
        ...prevState,
        currentPassword: ''
      }));
    } catch (error) {
      console.error('Error updating user data:', error);
      setError(error.response?.data?.error || 'Fehler beim Aktualisieren der Daten.');
    }
  };

  const handlePasswordChange = async () => {
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setError('Die neuen Passwörter stimmen nicht überein.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. User not authenticated.');
        return;
      }

      const response = await axios.put(`${BackendAddress}/api/auth/changePassword`, {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSuccessMessage(response.data.message);
      setIsEditingPassword(false);
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      });
    } catch (error) {
      console.error('Error updating password:', error);
      setError(error.response?.data?.error || 'Fehler beim Ändern des Passworts.');
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Header>
              <h3>Mein Account</h3>
            </Card.Header>
            <Card.Body>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              <Form>
                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Benutzername</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={userData.username}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>E-Mail</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Form.Group>

                {isEditing && (
                  <Form.Group className="mb-3" controlId="formCurrentPassword">
                    <Form.Label>Aktuelles Passwort</Form.Label>
                    <Form.Control
                      type="password"
                      name="currentPassword"
                      value={userData.currentPassword}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                )}

                <div className="d-flex justify-content-between">
                  {!isEditing ? (
                    <Button variant="primary" onClick={handleEditToggle}>
                      Bearbeiten
                    </Button>
                  ) : (
                    <Button variant="success" onClick={handleSaveChanges}>
                      Änderungen speichern
                    </Button>
                  )}
                  {isEditing && (
                    <Button variant="secondary" onClick={handleEditToggle}>
                      Abbrechen
                    </Button>
                  )}
                </div>
              </Form>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header>
              <h3>Passwort ändern</h3>
            </Card.Header>
            <Card.Body>
              {isEditingPassword && (
                <Form>
                  <Form.Group className="mb-3" controlId="formCurrentPassword">
                    <Form.Label>Aktuelles Passwort</Form.Label>
                    <Form.Control
                      type="password"
                      name="currentPassword"
                      value={passwordData.currentPassword}
                      onChange={handlePasswordInputChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formNewPassword">
                    <Form.Label>Neues Passwort</Form.Label>
                    <Form.Control
                      type="password"
                      name="newPassword"
                      value={passwordData.newPassword}
                      onChange={handlePasswordInputChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formConfirmNewPassword">
                    <Form.Label>Neues Passwort bestätigen</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmNewPassword"
                      value={passwordData.confirmNewPassword}
                      onChange={handlePasswordInputChange}
                    />
                  </Form.Group>

                  <Button variant="success" onClick={handlePasswordChange}>
                    Passwort ändern
                  </Button>
                </Form>
              )}

              {!isEditingPassword && (
                <Button variant="primary" onClick={handlePasswordEditToggle}>
                  Passwort bearbeiten
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
