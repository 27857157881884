import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Form, Button, Badge, Alert } from 'react-bootstrap';
import axios from 'axios';

const Servers = () => {
  const [openInNewTab, setOpenInNewTab] = useState(() => {
    const savedPreference = localStorage.getItem('openInNewTab');
    return savedPreference ? JSON.parse(savedPreference) : true;
  });

  const [data, setData] = useState([]);
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';
  const Pteroadress = isDebugging ? 'https://pteropanel.dein-mc-server.de' : 'https://pteropanel.dein-mc-server.de';
  const [serverStatus, setServerStatus] = useState({});
  const [showForceStop, setShowForceStop] = useState({});
  const [intervalTrigger, setIntervalTrigger] = useState(0);
  const [expanded, setExpanded] = useState({}); // Zustand für erweiterbare Karten
  const [autoPause, setAutoPause] = useState({}); // Zustand für Auto Pause
  const [noServersFound, setNoServersFound] = useState(false); // Zustand für die Anzeige von "Keine Server gefunden"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const res = await axios.get(`${BackendAddress}/api/server/servers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data.message) {
          // Wenn keine Server gefunden wurden
          setData([]);
          setNoServersFound(true); // Keine Server gefunden
        } else {
          setNoServersFound(false); // Server gefunden
          setData(res.data);
          res.data.forEach(server => fetchServerStatus(server.attributes.identifier));
        }
      } catch (err) {
        console.error(err);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    if (data.length > 0) {
      const interval = setInterval(() => {
        data.forEach(server => fetchServerStatus(server.attributes.identifier));
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [data, intervalTrigger]);

  const fetchServerStatus = async (identifier) => {
    try {
      const token = localStorage.getItem('token');
      const res = await axios.get(`${BackendAddress}/api/server/servers/${identifier}/resources`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setServerStatus(prevStatus => ({
        ...prevStatus,
        [identifier]: res.data.attributes.current_state,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSwitchChange = () => {
    const newValue = !openInNewTab;
    setOpenInNewTab(newValue);
    localStorage.setItem('openInNewTab', JSON.stringify(newValue));
  };

  const sendtoWebseite = (identifier) => {
    const target = openInNewTab ? '_blank' : '_self';
    window.open(`${Pteroadress}/server/${identifier}`, target);
  };

  const controlServer = async (identifier, action) => {
    try {
      if (action === 'start') {
        setServerStatus(prevStatus => ({
          ...prevStatus,
          [identifier]: 'starting',
        }));
      } else if (action === 'stop') {
        setServerStatus(prevStatus => ({
          ...prevStatus,
          [identifier]: 'stopping',
        }));
      } else if (action === 'restart') {
        setServerStatus(prevStatus => ({
          ...prevStatus,
          [identifier]: 'restarting',
        }));
      } else if (action === 'kill') {
        setServerStatus(prevStatus => ({
          ...prevStatus,
          [identifier]: 'force stopping',
        }));
      }

      const token = localStorage.getItem('token');
      await axios.post(
        `${BackendAddress}/api/server/servers/${identifier}/power`,
        { signal: action },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setIntervalTrigger(prev => prev + 1);
      
      if (action === 'stop') {
        setShowForceStop(prev => ({
          ...prev,
          [identifier]: true,
        }));
      } else {
        setShowForceStop(prev => ({
          ...prev,
          [identifier]: false,
        }));
      }
    } catch (err) {
      console.error(err);
      alert('Aktion fehlgeschlagen');
    }
  };

  const toggleExpand = (identifier) => {
    setExpanded(prev => ({ ...prev, [identifier]: !prev[identifier] }));
  };

  const handleAutoPauseChange = (identifier) => {
    setAutoPause(prev => ({ ...prev, [identifier]: !prev[identifier] }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'running':
        return 'green';
      case 'stopping':
      case 'starting':
      case 'restarting':
        return 'yellow';
      case 'offline':
      default:
        return 'red';
    }
  };

  return (
    <div>
      <Container className="py-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="text-center mb-5">Meine Server</h1>
          <div className="d-flex align-items-center ms-auto">
            <Form.Label htmlFor="openInNewTabSwitch" className="mb-0 me-2">
              {openInNewTab ? 'Öffnet Verwaltungsseite im neuen Tab' : 'Öffnet Verwaltungsseite im gleichen Tab'}
            </Form.Label>
            <Form.Check 
              type="switch"
              id="openInNewTabSwitch"
              checked={openInNewTab}
              onChange={handleSwitchChange}
              className="ms-2"
              style={{ display: 'inline-flex', alignItems: 'center' }}
            />
          </div>
        </div>

        {/* Anzeige, wenn keine Server gefunden wurden */}
        {noServersFound && (
          <Alert variant="warning" className="text-center">
            Keine Server gefunden. Erstelle einen neuen Server, um fortzufahren.
          </Alert>
        )}

        <Row>
          {data.length > 0 && data.map(server => (
            <Col sm={12} md={6} lg={4} key={server.attributes.internal_id} className="mb-4">
              <Card>
                <Card.Body className="d-flex flex-column">
                  <Card.Title className="mb-3 text-primary">{server.attributes.name}</Card.Title>
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: getStatusColor(serverStatus[server.attributes.identifier]),
                        marginRight: '8px',
                      }}
                    ></div>
                    <span>Status: {serverStatus[server.attributes.identifier] || 'Lädt...'}</span>
                  </div>

                  <Card.Text className="flex-grow-1">
                    Empfohlen für eine große Welt mit vielen Freunden, Plugins und einigen Mods
                  </Card.Text>
                  <div className="d-flex justify-content-around mt-auto">
                    <Button
                      variant="success"
                      onClick={() => controlServer(server.attributes.identifier, 'start')}
                      disabled={serverStatus[server.attributes.identifier] === 'running'}
                    >
                      Starten
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => controlServer(server.attributes.identifier, 'stop')}
                      disabled={serverStatus[server.attributes.identifier] === 'offline'}
                    >
                      Stoppen
                    </Button>
                    <Button
                      variant="warning"
                      onClick={() => controlServer(server.attributes.identifier, 'restart')}
                      disabled={serverStatus[server.attributes.identifier] === 'offline'}
                    >
                      Neustarten
                    </Button>
                  </div>
                  {showForceStop[server.attributes.identifier] && (
                    <div className="mt-3 d-flex justify-content-center">
                      <Button
                        variant="danger"
                        onClick={() => controlServer(server.attributes.identifier, 'kill')}
                      >
                        Force Stop (Kill)
                      </Button>
                    </div>
                  )}
                  <Button 
                    variant="link" 
                    onClick={() => toggleExpand(server.attributes.identifier)}
                    className="mt-3"
                  >
                    {expanded[server.attributes.identifier] ? 'Weniger Optionen' : 'Mehr Optionen'}
                  </Button>
                  {expanded[server.attributes.identifier] && (
                    <div className="mt-2">
                      <Form.Group controlId={`autoPauseSwitch-${server.attributes.identifier}`}>
                        <Form.Label>Auto Pause</Form.Label>
                        <Form.Check 
                          type="switch"
                          checked={autoPause[server.attributes.identifier] || false}
                          onChange={() => handleAutoPauseChange(server.attributes.identifier)}
                        />
                      </Form.Group>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="py-3">
        <h3 className="text-center">
          Zum Anmelden und Verwalten verwende initial deine Anmeldedaten von dieser Webseite. 
          Wenn du dein Passwort hier änderst, wird dies automatisch beim Server-Verwalten geändert.
        </h3>
      </Container>
    </div>
  );
};

export default Servers;
