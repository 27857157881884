import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Button, Card, Form } from 'react-bootstrap';
import axios from 'axios';
import RangeSlider from 'react-bootstrap-range-slider';

const Erstellen = () => {
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';
  const location = useLocation();
  const navigate = useNavigate();
  const [packageName, setPackageName] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [error, setError] = useState(null);
  const [packagePrice, setPackagePrice] = useState(0);

  const [ram, setRam] = useState(4);
  const [storage, setStorage] = useState(100);
  const [cpu, setCpu] = useState(1);

  const calculateCustomPackagePrice = () => {
    return ram + storage / 25 + cpu;
  };

  useEffect(() => {
    if (location.state && location.state.packageName) {
      setPackageName(location.state.packageName);

      switch (location.state.packageName) {
        case 'Bronze':
          setPackagePrice(5);
          break;
        case 'Silber':
          setPackagePrice(10);
          break;
        case 'Gold':
          setPackagePrice(15);
          break;
        case 'Advanced Bronze':
          setPackagePrice(10);
          break;
        case 'Advanced Silber':
          setPackagePrice(15);
          break;
        case 'Advanced Gold':
          setPackagePrice(20);
          break;
        case 'Individuell':
          setPackagePrice(calculateCustomPackagePrice());
          break;
        default:
          setPackagePrice(0);
          break;
      }
    }

    const balance = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await axios.get(`${BackendAddress}/api/balance/balance`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserBalance(response.data);
      } catch (err) {
        setError('Fehler beim Abrufen des Guthabens.');
      }
    };
    balance();
  }, [location, ram, storage, cpu]);

  const handlePackageSelect = (selectedPackage) => {
    setPackageName(selectedPackage);

    switch (selectedPackage) {
      case 'Bronze':
        setPackagePrice(5);
        break;
      case 'Silber':
        setPackagePrice(10);
        break;
      case 'Gold':
        setPackagePrice(15);
        break;
      case 'Advanced Bronze':
        setPackagePrice(10);
        break;
      case 'Advanced Silber':
        setPackagePrice(15);
        break;
      case 'Advanced Gold':
        setPackagePrice(20);
        break;
      case 'Individuell':
        setPackagePrice(calculateCustomPackagePrice());
        break;
      default:
        setPackagePrice(0);
        break;
    }
  };

  const handleServerCreation = async () => {
    const token = localStorage.getItem('token');
  
    const requestData = {
      packageName,
      ram,
      storage,
      cpu,
    };
  
    if (userBalance >= packagePrice) {
      try {
        const response = await axios.post(`${BackendAddress}/api/erstellen/erstellen`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate('/server');
      } catch (error) {
        console.error('Fehler bei der Erstellung des Servers:', error);
        setError('Fehler bei der Erstellung des Servers.');
      }
    } else {
      setError('Nicht genug Guthaben!');
    }
  };

  return (
    <Container>
      <h1 className="my-4">Erstelle deinen Server</h1>
      <div className="d-flex justify-content-between flex-wrap">
        {/* Bronze Paket */}
        <Card 
          as="a" 
          onClick={() => handlePackageSelect('Bronze')} 
          className="text-decoration-none mb-3" 
          style={{ cursor: 'pointer', width: '18rem' }}
        >
          <Card.Body>
            <Card.Title>Bronze</Card.Title>
            <p>5€ / Monat</p>
            <Card.Text>Empfohlen für eine Hand voll Freunde mit wenigen Plugins und ohne Mods</Card.Text>
          </Card.Body>
        </Card>
        {/* Silber Paket */}
        <Card 
          as="a" 
          onClick={() => handlePackageSelect('Silber')} 
          className="text-decoration-none mb-3" 
          style={{ cursor: 'pointer', width: '18rem' }}
        >
          <Card.Body>
            <Card.Title>Silber</Card.Title>
            <p>10€ / Monat</p>
            <Card.Text>Empfohlen für eine große Welt mit einigen Freunden und kleinen Plugins und Mods</Card.Text>
          </Card.Body>
        </Card>
        {/* Gold Paket */}
        <Card 
          as="a" 
          onClick={() => handlePackageSelect('Gold')} 
          className="text-decoration-none mb-3" 
          style={{ cursor: 'pointer', width: '18rem' }}
        >
          <Card.Body>
            <Card.Title>Gold</Card.Title>
            <p>15€ / Monat</p>
            <Card.Text>Empfohlen für eine große Welt mit vielen Freunden und einigen Plugins und Mods</Card.Text>
          </Card.Body>
        </Card>
        </div>
        <div className="d-flex justify-content-between flex-wrap">
        {/* Advanced Bronze Paket */}
        <Card 
          as="a" 
          onClick={() => handlePackageSelect('Advanced Bronze')} 
          className="text-decoration-none mb-3" 
          style={{ cursor: 'pointer', width: '18rem' }}
        >
          <Card.Body>
            <Card.Title>Advanced Bronze</Card.Title>
            <p>10€ / Monat</p>
            <Card.Text>Empfohlen für eine große Welt mit vielen Freunden und Plugins und einigen Mods</Card.Text>
          </Card.Body>
        </Card>
        {/* Advanced Silber Paket */}
        <Card 
          as="a" 
          onClick={() => handlePackageSelect('Advanced Silber')} 
          className="text-decoration-none mb-3" 
          style={{ cursor: 'pointer', width: '18rem' }}
        >
          <Card.Body>
            <Card.Title>Advanced Silber</Card.Title>
            <p>15€ / Monat</p>
            <Card.Text>Empfohlen für eine große Welt mit einigen Freunden und Plugins und kleine Modpacks</Card.Text>
          </Card.Body>
        </Card>
        {/* Advanced Gold Paket */}
        <Card 
          as="a" 
          onClick={() => handlePackageSelect('Advanced Gold')} 
          className="text-decoration-none mb-3" 
          style={{ cursor: 'pointer', width: '18rem' }}
        >
          <Card.Body>
            <Card.Title>Advanced Gold</Card.Title>
            <p>20€ / Monat</p>
            <Card.Text>Empfohlen für eine große Welt mit vielen Freunden und Plugins und Modpacks</Card.Text>
          </Card.Body>
        </Card>
        </div>


        {/* Individuelles Paket */}
        <Card 
          as="a" 
          onClick={() => handlePackageSelect('Individuell')} 
          className="text-decoration-none mb-3 container" 
          style={{ cursor: 'pointer', width: '18rem' }}
        >
          <Card.Body>
            <Card.Title>Individuell</Card.Title>
            <Card.Text>Passe deinen Server ganz nach deinem Bedarf an</Card.Text>
            {packageName === 'Individuell' && (
              <>
                <Form.Group controlId="formRam" className="mt-3">
                  <Form.Label>Arbeitsspeicher (GB): {ram}</Form.Label>
                  <RangeSlider
                    value={ram}
                    onChange={(e) => setRam(parseInt(e.target.value))}
                    min={1}
                    max={16}
                    tooltip='auto'
                  />
                </Form.Group>
                <Form.Group controlId="formStorage" className="mt-3">
                  <Form.Label>Speicher (GB): {storage}</Form.Label>
                  <RangeSlider
                    value={storage}
                    onChange={(e) => setStorage(parseInt(e.target.value))}
                    min={25}
                    max={500}
                    step={25}
                    tooltip='auto'
                  />
                </Form.Group>
                <Form.Group controlId="formCpu" className="mt-3">
                  <Form.Label>CPU-Kerne: {cpu}</Form.Label>
                  <RangeSlider
                    value={cpu}
                    onChange={(e) => setCpu(parseInt(e.target.value))}
                    min={1}
                    max={8}
                    tooltip='auto'
                  />
                </Form.Group>
                <h5 className="mt-3">Gesamtkosten: {packagePrice} € / Monat</h5>
              </>
            )}
          </Card.Body>
        </Card>
      

      <p className="mt-4">Gewähltes Paket: {packageName}</p>
      <p>Preis des Pakets: {packagePrice}€</p>
      <p>Verfügbares Guthaben: {userBalance}€</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Button variant="primary" onClick={handleServerCreation} disabled={!packageName}>
        Server erstellen
      </Button>
    </Container>
  );
};

export default Erstellen;
