import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, Button } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false
  });

  const [error, setError] = useState(null); // Zustand für Fehlermeldungen

  const { usernameOrEmail, password, rememberMe } = formData;
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    setError(null); // Reset der Fehlermeldung vor dem Versuch
  
    try {
      const res = await axios.post(`${BackendAddress}/api/auth/login`, { usernameOrEmail, password });
  
      if (res && res.data) {
        const { token, username } = res.data;

        // Speichere den Token im localStorage, wenn "Angemeldet bleiben" aktiviert ist
        if (rememberMe) {
          localStorage.setItem('token', token);
          localStorage.setItem('username', username);
          localStorage.setItem('tokenExpiry', new Date().getTime() + 30 * 24 * 60 * 60 * 1000); // 30 Tage in Millisekunden
        } else {
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('username', username);
        }

        onLogin({ username }); // Username an die Elternkomponente übergeben
        navigate(from, { replace: true });
      } else {
        setError('Unerwartete Antwort vom Server');
        console.error('Unexpected response structure', res);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.message || 'Login fehlgeschlagen');
        console.error('Login error:', err.response.data);
      } else {
        setError('Ein unerwarteter Fehler ist aufgetreten.');
        console.error('An unexpected error occurred:', err);
      }
    }
  };

  const fillTestCredentials = () => {
    setFormData({
      usernameOrEmail: 'test',
      password: 'test123',
      rememberMe: true
    });
  };

  return (
    <Container>
      <h2>Login</h2>
      <Button 
        variant="secondary" 
        onClick={fillTestCredentials} 
      >
        Fill Test Credentials
      </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Anzeige der Fehlermeldung */}
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="formUsername">
          <Form.Label>Username oder E-Mail</Form.Label>
          <Form.Control 
            type="text" 
            name="usernameOrEmail" 
            value={usernameOrEmail} 
            onChange={onChange} 
            required 
          />
        </Form.Group>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            name="password" 
            value={password} 
            onChange={onChange} 
            required 
          />
        </Form.Group>
        <Form.Group controlId="formRememberMe">
          <Form.Check 
            type="checkbox" 
            name="rememberMe" 
            label="Angemeldet bleiben" 
            checked={rememberMe}
            onChange={() => setFormData({ ...formData, rememberMe: !rememberMe })}
          />
        </Form.Group>
        <Button variant="primary" type="submit">Login</Button>
      </Form>
    </Container>
  );
};

export default Login;
