import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

const Home = () => {
  const [status, setStatus] = useState(''); // Status als String
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Lese den Status aus der URL
    const queryParams = new URLSearchParams(window.location.search);
    const statusParam = queryParams.get('status');

    // Setze den Status und die Nachricht
    setStatus(statusParam);
    if (statusParam === 'success') {
      setMessage('Ihre E-Mail wurde erfolgreich bestätigt. Sie werden in wenigen Sekunden weitergeleitet...');
    } else {
      setMessage('Die E-Mail-Bestätigung ist fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }

    // Weiterleitung nach 5 Sekunden
    setTimeout(() => {
      window.location.href = '/'; // Ändere die URL auf die Seite, auf die du weiterleiten möchtest
    }, 5000);
  }, []);

  return (
    <Container style={styles.container}>
      <div style={status === 'success' ? styles.successMessage : styles.errorMessage}>
        <h1>{status === 'success' ? 'E-Mail Bestätigt!' : 'Bestätigung fehlgeschlagen!'}</h1>
        <p>{message}</p>
      </div>
    </Container>
  );
};

// CSS-Stile
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f7f7f7',
  },
  successMessage: {
    color: 'green',
    textAlign: 'center',
    padding: '20px',
    border: '2px solid green',
    borderRadius: '10px',
    backgroundColor: '#e0ffe0',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    padding: '20px',
    border: '2px solid red',
    borderRadius: '10px',
    backgroundColor: '#ffe0e0',
  },
};

export default Home;
